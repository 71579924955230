<template>
  <div class="flex flex-column gap-1">
    <div v-if="!noLabel">Date:</div>
    <div class="flex gap-2 align-items-center">
      <div>
        <b-button
          variant="primary"
          size="sm"
          @click="addPeriod(-1)"
          class="period-button"
          :disabled="rangeType === 'all'"
        >
          <i class="fas fa-chevron-left"></i>
        </b-button>
      </div>

      <date-picker
        class="date-picker"
        v-model="value.date_from"
        :format="dateFormat"
        :type="rangeType"
        valueType="YYYY-MM-DD"
        :clearable="false"
        @input="onDateSelected()"
        :lang="datepickerLocale"
      >
        <template #footer>
          <div class="flex justify-between">
            <button class="mx-btn mx-btn-text" @click="onDateReset()">
              Reset
            </button>

            <button-select
              v-model="rangeType"
              :options="rangeTypes"
              size="xs"
              @input="applyRange()"
            ></button-select>
          </div>
        </template>
      </date-picker>

      <b-button
        variant="primary"
        size="sm"
        @click="addPeriod(1)"
        class="period-button"
        :disabled="rangeType === 'all'"
      >
        <i class="fas fa-chevron-right"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { datepickerLocale } from '@/helpers';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'DateRange',
  components: {
    ButtonSelect,
  },
  props: {
    value: Object,
    noLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    datepickerLocale() {
      return datepickerLocale;
    },
    rangeType: {
      get() {
        return this.value.date_range ?? 'all';
      },
      set(value) {
        this.value.date_range = value;
      },
    },
    dateFormat() {
      return this.dateFormats[this.rangeType];
    },
  },
  data() {
    return {
      dateFormats: {
        date: 'MMM DD, YYYY',
        month: 'MMM YYYY',
        year: 'YYYY',
      },
      rangeTypes: [
        { text: 'Day', value: 'date' },
        { text: 'Month', value: 'month' },
        { text: 'Year', value: 'year' },
        { text: 'All', value: 'all' },
      ],
      rangePeriodTypes: {
        date: 'days',
        month: 'months',
        year: 'years',
      },
    };
  },
  methods: {
    openPopup() {
      this.$nextTick(() => {
        /* eslint no-underscore-dangle: 0 */
        document.querySelector('.date-picker').__vue__.openPopup();
      });
    },
    closePopup() {
      /* eslint no-underscore-dangle: 0 */
      document.querySelector('.date-picker').__vue__.closePopup();
    },
    emitInput() {
      this.$emit('input', this.value);
    },
    addPeriod(period) {
      if (this.rangeType === 'all') {
        return;
      }
      const periodType = this.rangePeriodTypes[this.rangeType];
      const periodDate = moment(this.value.date_from).add(period, periodType);
      this.value.date_from = periodDate.startOf(periodType).format('YYYY-MM-DD');
      this.value.date_to = periodDate.endOf(periodType).format('YYYY-MM-DD');
      this.emitInput();
    },
    initiateDates() {
      if (this.rangeType === 'all') {
        this.value.date_from = null;
        this.value.date_to = null;
      } else {
        this.value.date_from = moment(this.value.date_from || new Date()).startOf(this.rangeType).format('YYYY-MM-DD');
        this.value.date_to = moment(this.value.date_from || new Date()).endOf(this.rangeType).format('YYYY-MM-DD');
      }
      this.emitInput();
    },
    applyRange() {
      this.closePopup();
      if (this.rangeType !== 'all') {
        this.openPopup();
      }
      this.initiateDates();
    },
    onDateSelected() {
      if (this.rangeType === 'all') {
        this.rangeType = 'date';
      }
      this.value.date_to = moment(this.value.date_from).endOf(this.rangeType).format('YYYY-MM-DD');
      this.emitInput();
    },
    onDateReset() {
      this.value.date_from = null;
      this.initiateDates();
      this.closePopup();
    },
  },
  created() {
    if (this.value.date_from === null) {
      this.initiateDates();
    }
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  width: 130px;
}

.period-button {
  height: 34px;
}
</style>
